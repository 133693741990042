var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"ATAHForm"},[_c('b-form',[_c('b-card',{attrs:{"title":_vm.$t('g.temperatureAndhumidity')}},[_c('b-row',[(_vm.lab_name_options)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"labName","label":_vm.$t('g.labName')}},[_c('validation-provider',{attrs:{"name":"lab name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.lab_name_options,"state":errors.length > 0 ? false : null,"label":"name","clearable":false,"multiple":""},model:{value:(_vm.form_data.lab_name),callback:function ($$v) {_vm.$set(_vm.form_data, "lab_name", $$v)},expression:"form_data.lab_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}],null,false,2115402551)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"date","label":_vm.$t('g.date')}},[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.date),callback:function ($$v) {_vm.$set(_vm.form_data, "date", $$v)},expression:"form_data.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"temperature","label":_vm.$t('g.temperature')}},[_c('validation-provider',{attrs:{"name":"temperature","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"°C"}},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheTemperatureHere')},model:{value:(_vm.form_data.temperature),callback:function ($$v) {_vm.$set(_vm.form_data, "temperature", $$v)},expression:"form_data.temperature"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"humidity","label":_vm.$t('g.humidity')}},[_c('validation-provider',{attrs:{"name":"humidity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheHumidityHere')},model:{value:(_vm.form_data.humidity),callback:function ($$v) {_vm.$set(_vm.form_data, "humidity", $$v)},expression:"form_data.humidity"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t("g.submit"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }