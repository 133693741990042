<template>
  <div>
    <validation-observer ref="ATAHForm">
      <b-form>
        <b-card :title="$t('g.temperatureAndhumidity')">
          <b-row>
            <!-- Lab name -->
            <b-col v-if="lab_name_options" md="4">
              <b-form-group label-for="labName" :label="$t('g.labName')">
                <validation-provider
                  #default="{ errors }"
                  name="lab name"
                  rules="required"
                >
                  <v-select
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="lab_name_options"
                    :state="errors.length > 0 ? false : null"
                    label="name"
                    :clearable="false"
                    v-model="form_data.lab_name"
                    multiple
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Lab name -->
            <!-- Date -->
            <b-col md="4">
              <b-form-group label-for="date" :label="$t('g.date')">
                <validation-provider
                  #default="{ errors }"
                  name="date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.date"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Date -->
            <!-- Temperature -->

            <b-col md="4">
              <b-form-group
                label-for="temperature"
                :label="$t('g.temperature')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="temperature"
                  rules="required"
                >
                  <b-input-group append="°C">
                    <b-form-input
                      v-model="form_data.temperature"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('g.placeholder.enterTheTemperatureHere')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Temperature -->

            <!-- Humidity -->

            <b-col md="4">
              <b-form-group label-for="humidity" :label="$t('g.humidity')">
                <validation-provider
                  #default="{ errors }"
                  name="humidity"
                  rules="required"
                >
                  <b-input-group append="%">
                    <b-form-input
                      v-model="form_data.humidity"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('g.placeholder.enterTheHumidityHere')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Humidity -->

            <b-col cols="12">
              <b-button @click.prevent="validationForm" variant="primary">
                {{ $t("g.submit") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { required } from "@validations";
import {
  BForm,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormTextarea,
  BInputGroup,
  BButton,
} from "bootstrap-vue";
export default {
  components: {
    BForm,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BInputGroup,
    BFormTextarea,
    BButton,
    vSelect,
  },
  data() {
    return {
      form_data: {
        date: new Date(),
        lab_name: null,
        temperature: null,
        humidity: null,
      },
      lab_name_options: [
        {
          id: 1,
          name: "Saitco 1",
        },
        {
          id: 2,
          name: "Saitco 2",
        },
        {
          id: 3,
          name: "Saitco 3",
        },
        {
          id: 4,
          name: "Saitco 4",
        },
      ],
    };
  },
  methods: {
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    clearForm() {
      let form = this.form_data,
        i;
      for (i in form) {
        form[i] = null;
        if (i === "date") {
          form[i] = new Date();
        }
      }
    },
    validationForm() {
      this.$refs.ATAHForm.validate().then((success) => {
        if (success) {
          this.submitData();
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    submitData() {
      let formData = new FormData();
      for (let item in this.form_data) {
        formData.append(item, this.form_data[item]);
      }

      this.$http
        .post("http://www.localhost:3004/data", formData)
        .then((res) => {
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );
          this.clearForm();
          this.$refs.ATAHForm.reset();
        })
        .catch((err) => {
          for (const key in err.response.data.errors) {
            this.makeToast(
              "danger",
              err.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
          console.log(err);
        });
    },
  },
};
</script>
