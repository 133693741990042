import { render, staticRenderFns } from "./addTemperatureAndHumidity.vue?vue&type=template&id=18c8a4de&"
import script from "./addTemperatureAndHumidity.vue?vue&type=script&lang=js&"
export * from "./addTemperatureAndHumidity.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports